import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../../../config";
import "../../../styles/investor/investor-dashboard-styles/new-invoice.scss";
import { convertTime } from "../../../util/convertTime";
import { formatAmount } from "../../../util/formatAmount";
import { InvestorActivationModalForm } from "./InvestorActivationFormModal";
import { InvestorInvoiceDetailModal } from "./InvestorInvoiceDetailModal";
// import "../../index"
// import {MobileInvoiceDetailModal} from './MobileInvoiceDetailModal';

export const NewInvoices = (props) => {
  const {
    roi,
    invoiceName,
    amount,
    amountUSD,
    bidders,
    vendor,
    dueDate,
    payRate,
    id,
    dueTime,
    loadInvestor,
    verified,
  } = props;
  const userCurrency =
  localStorage.getItem("userCurrency") &&
  JSON.parse(localStorage.getItem("userCurrency"));

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState();
  const [activateModal, setActivateModal] = useState(false);

  const [time, setTime] = useState("");

  const onModalOpen = async () => {
    const token = localStorage.getItem("token");

    setModal(true);

    

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/invoice_details/${id}`,
        config
      );

      setIsLoading(false);
      console.log(data);

      if (data.success) setModalData(data.invoice_details[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const onModalClose = () => {
    setModal(false);
    setModalData(undefined);
  };

  useEffect(() => {
    if (convertTime(dueTime).difference <= 0) {
      setTime("Bid Closed");
    } else {
      const timer = setTimeout(() => {
        setTime(convertTime(dueTime));
      }, [1000]);

      return () => clearTimeout(timer);
    }
  }, [time]);
  const shortInvoiceName = invoiceName.substring(0, 17) + "...";
  return (
    <div className="new-invoice-container">
      <div className="new-invoice-part-one">
        <h2 className="new-invoice-name">{shortInvoiceName}</h2>
        <h2 className="new-invoice-amount">{userCurrency === "naira" ? `₦`:`$` } {amount}</h2>
        <div className="new-double-container">
          <h4 className="new-bidder-number mobile-hide">
            No of Bidders: <span className="rise-font">{bidders}</span>
          </h4>
          <h4 className="new-rate-on-return">
            Max ROI:{" "}
            <span className="rise-font" style={{ color: "red" }}>
              {roi}%
            </span>
          </h4>
        </div>

        <h4 className="new-invoice-vendor mobile-hide">
          Vendor: <span className="rise-font new-vendor">{vendor}</span>
        </h4>
      </div>
      <div className="new-invoice-part-two">
        <section className="part-two-top">
          {time !== "Bid Closed" && (
            <span onClick={onModalOpen} className="new-invoice-edit-btn">
              <span className="mobile-hide">View</span> Details
            </span>
          )}

          <div className="new-count-down-time">
            {time === "Bid Closed" ? (
              time
            ) : (
              <>
                <span className="hour-panel">
                  <h5 className="label-panel">Days</h5>
                  <h1 className="timed-digit">{time.days} :</h1>
                </span>

                <span className="minute-panel">
                  <h5 className="label-panel">Hrs</h5>
                  <h1 className="timed-digit">{time.hrs} :</h1>
                </span>

                <span className="minute-panel">
                  <h5 className="label-panel">Mins</h5>
                  <h1 className="timed-digit">{time.mins} :</h1>
                </span>

                <span className="seconds-panel">
                  <h5 className="label-panel">Sec</h5>
                  <h1 className="timed-digit">{time.sec}</h1>
                </span>
              </>
            )}
          </div>
        </section>
        <h4 className="new-invoice-date mobile-hide">
          Due date:{" "}
          <span className="rise-font new-invoice-date">{dueDate}</span>
        </h4>
      </div>
      <InvestorInvoiceDetailModal
        loading={isLoading}
        onClose={onModalClose}
        modal={modal}
        id={id}
        dueDate={moment(modalData?.dueDate).format("MMM Do, YYYY")}
        transPrice={amount}
        transName={modalData?.invoice_name}
        dueTime={modalData?.bid_close_time}
        ROI={modalData?.max_discount.toFixed(2)}
        credit={modalData?.seller_credit_score}
        bidderNumber={modalData?.no_of_bidders}
        sellerName={modalData?.seller_name}
        vendorPhoneNumber={modalData?.vendor_phone}
        payRate={payRate}
        invoiceURL={modalData?.invoice_url}
        vendorEmail={modalData?.vendor_email}
        maxPayable={formatAmount(modalData?.payable_amount_by_investor)}
        maxPayable_={userCurrency === "naira" ? modalData?.payable_amount_by_investor :modalData?.payable_amount_by_investor_USD}
        maxPayableUSD={formatAmount(modalData?.payable_amount_by_investor_USD)}
        invoiceAmount={modalData?.invoice_amount}
        invoiceAmountUSD={modalData?.invoice_amount_USD}
        vendorName={modalData?.vendor_name}
        invoiceName={invoiceName}
        loadInvestor={loadInvestor}
        verified={verified}
        onActivate={() => {
          setModal(false);
          setActivateModal(true);
        }}
      />
      <InvestorActivationModalForm
        modal={activateModal}
        onClose={() => setActivateModal(false)}
      />
    </div>
  );
};
