import axios from "axios";
import { BACKEND_URL } from "../../../config";
import React, { useState } from "react";
import "../../../styles/vendor/vendor-index/vendor-invoice-card.scss";
import { VendorConfirmationModal } from "../vendor-invoice/VendorConfirmationModal";
import VendorPayNowModalCard from "../vendor-invoice/VendorPayNowModalCard";
import { VendorSellerDetailModal } from "./vendorSellerDetailModal";

const VendorInvoiceCard = (props) => {
  const {
    invoiceName,
    price,
    seller,
    dueDate,
    status,
    url,
    id,
    loadData,
    sellerId,
  } = props;

  const [confirm, setConfirm] = useState(false);
  const [payNow, setPayNow] = useState(false);
  const [sellerDetail, setSellerDetail] = useState(false);

  const [sellerDetailsData, setSellerDetailsData] = useState();
  const [isSellerLoading, setIsSellerLoading] = useState(false);

  const toggleConfirmation = () => {
    setConfirm(!confirm);
  };

  const togglePayNow = () => {
    setPayNow(!payNow);
  };

  const onSellerDetailOpen = async () => {
    const token = localStorage.getItem("token");

    setSellerDetail(true);

    try {
      setIsSellerLoading(true);

      const body = {
        user_id: sellerId,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        `${BACKEND_URL}/seller_user_details/${token}`,
        body,
        config
      );
      setIsSellerLoading(false);
      console.log(data);

      if (data.success) setSellerDetailsData(data.user_details);
    } catch (error) {
      console.error(error);
    }
  };

  const onSellerDetailClose = () => {
    setSellerDetail(false);
    setSellerDetailsData(undefined);
  };

  return (
    <>
      <tr className="vendor-invoice-cards">
        <td>
          <a
            className="vendor-invoice-link"
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            {invoiceName}
          </a>
        </td>
        <td>₦{price}</td>
        <td>
          <span
            className="indicator pending"
            style={{ display: status === "pending" ? "flex" : "none" }}
          >
            Pending
          </span>

          <span
            className="indicator approved"
            style={{ display: status === "approved" ? "flex" : "none" }}
          >
            Approved
          </span>

          <span
            className="indicator sold"
            style={{ display: status === "sold" ? "flex" : "none" }}
          >
            Sold
          </span>

          <span
            className="indicator closed"
            style={{ display: status === "closed" ? "flex" : "none" }}
          >
            Closed
          </span>

          <span
            className="indicator due"
            style={{ display: status === "due" ? "flex" : "none" }}
          >
            Due
          </span>
        </td>
        <td onClick={onSellerDetailOpen}>{seller}</td>

        {/* <td>{payAmount}</td> */}
        <td>{dueDate}</td>
        <td>
          {status === "pending" ? (
            <button
              className="vendor-invoice-btn active-vendor-btn"
              onClick={toggleConfirmation}
            >
              Confirm
            </button>
          ) : status === "due" ? (
            <button
              className="vendor-invoice-btn active-vendor-btn"
              onClick={togglePayNow}
            >
              Pay Now
            </button>
          ) : (
            <button disabled className="vendor-invoice-btn inactive-vendor-btn">
              Confirm
            </button>
          )}
        </td>
      </tr>

      <VendorConfirmationModal
        toggleChange={toggleConfirmation}
        modal={confirm}
        url={url}
        id={id}
        loadData={loadData}
      />

      <VendorPayNowModalCard
        controlModal={togglePayNow}
        modal={payNow}
        price={price}
        url={url}
        id={id}
        loadData={loadData}
        invoiceName={invoiceName}
      />

      <VendorSellerDetailModal
        onClose={onSellerDetailClose}
        modal={sellerDetail}
        sellerAddress={sellerDetailsData?.address}
        sellerName={sellerDetailsData?.companyName}
        typeBusiness={sellerDetailsData?.business_type}
        annualRevenue={sellerDetailsData?.revenue}
        phoneNumber={sellerDetailsData?.phone}
        country={sellerDetailsData?.country}
        emailAddress={sellerDetailsData?.email}
        creditScore={sellerDetailsData?.credit_score}
        numberStaff={sellerDetailsData?.staffNo}
        ninNumber={sellerDetailsData?.nin}
        bankVerificationNumber={sellerDetailsData?.bvn}
        cacNumber={sellerDetailsData?.cac}
        bankName={sellerDetailsData?.bank}
        accountName={sellerDetailsData?.account_name}
        accountNumber={sellerDetailsData?.account_no}
        memoNumber={sellerDetailsData?.memo_id}
        femaleCofounder={sellerDetailsData?.femaleCofounder}
        loading={isSellerLoading}
      />
    </>
  );
};

export default VendorInvoiceCard;
