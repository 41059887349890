import React, { useState, useEffect } from "react";
import AdminInvoiceCard from "../../components/admin-component/admin-invoice/AdminInvoiceCard";
import { AdminStatusBar } from "../../components/admin-component/AdminStatusBar";
import { NoInvoiceIcon } from "../../components/icons";
import "../../styles/admin/admin-invoice/admin-invoice-index.scss";
import { AnimateSharedLayout, motion } from "framer-motion";
import { BACKEND_URL } from "../../config";
import axios from "axios";
import { PageLoader } from "../../components/general-component/PageLoader";
import { SessionExpired } from "../../components/general-component/SessionExpired";
import { formatAmount } from "../../util/formatAmount";

const filterStatus = [
  "all",
  "pending",
  "approved",
  "sold",
  "closed",
  "due",
  "rejected",
];

const AdminInvoicePage = () => {
  const [selector, setSelector] = useState("all");
  const [invoiceProperty, setInvoiceProperty] = useState("all_invoices");

  const [invoiceData, setInvoiceData] = useState();
  const [expire, setExpire] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/admin_invoice/${token}`,
        config
      );
      console.log(data);
      setIsLoading(false);

      if (data.status === 202 && data.message === "Signature has expired")
        setExpire(true);

      if (data.success) setInvoiceData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) return <PageLoader />;

  if (expire) return <SessionExpired isAdmin />;

  return (
    <>
      <AdminStatusBar activated={true} loadData={loadData} />
      <div className="admin-invoice-page-container">
        <div className="admin-invoice-first-half-filter">
          <span className="admin-invoice-filter admin-input-first mobile-hide">
            <p className="admin-invoice-filter mobile-hide">Invoices</p>
            <div className="admin-invoice-filter-btn-holder">
              <AnimateSharedLayout>
                {filterStatus?.map((filter, i) => (
                  <button
                    key={i}
                    onClick={() => {
                      setSelector(filter);
                      setInvoiceProperty(`${filter}_invoices`);
                    }}
                    className={
                      selector === filter
                        ? "admin-invoice-btn active-status"
                        : "admin-invoice-btn"
                    }
                  >
                    {filter}
                    {selector === filter && (
                      <motion.span layoutId="filter-active" />
                    )}
                  </button>
                ))}
              </AnimateSharedLayout>
            </div>
          </span>
        </div>

        <div className="admin-invoice-second-half mobile-hide">
          <table className="admin-invoice-page-table">
            <tbody>
              <tr className="admin-invoice-page-header table">
                <th>Invoice Name</th>
                <th>Invoice Price</th>
                <th>Status</th>
                <th>Seller</th>
                <th>Vendor</th>
                <th></th>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="admin-invoice-full-scroller">
          {invoiceData?.[invoiceProperty]?.length ? (
            <div className="admin-invoice-data-holder mobile-hide">
              {invoiceData?.[invoiceProperty]?.map((invoice, index) => (
                <AdminInvoiceCard
                  loadData={loadData}
                  key={invoice?.invoice_id}
                  id={invoice?.invoice_id}
                  price={formatAmount(invoice?.invoice_amount)}
                  status={invoice?.status}
                  dueDate={invoice.dueDate}
                  seller={invoice?.seller}
                  vendor={invoice?.vendor}
                  invoiceName={invoice?.invoice_name}
                  url={invoice?.invoice_url}
                  sellerId={invoice?.seller_id}
                  vendorId={invoice?.vendor_id}
                />
              ))}
            </div>
          ) : (
            <div className="empty-invoice">
              <NoInvoiceIcon />
              <p>You currently do not have any Invoices </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminInvoicePage;
