import axios from "axios";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { BACKEND_URL } from "../../config";
import "../../styles/admin/admin-status-bar.scss";
import { ModalLoader } from "../general-component/ModalLoader";
import { Toolkit } from "../general-component/Toolkit";
import { ActivationSuccessModal } from "../seller-component/dashboard-component/ActivationSuccessModal";
import { AdminSuccessModal } from "./AdminSuccessModal";

export const AdminStatusBar = (props) => {
  const { activated, title, loadData } = props;

  const [success, setSuccess] = useState(null);
  const [paySuccess, setPaySuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //  const [modal, setModal] = useState(false);

  // const controlModal = () => {
  //   setModal(!modal);
  // };

  const handleCloseBids = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/close_bids/${token}`,
        config
      );
      console.log(data);
      setIsLoading(false);

      if (data.success) setSuccess(data);
    } catch (error) {
      setError("There was something wrong with the request");
      setIsLoading(false);
      console.error(error);
    }
  };

  const handlePayInvestors = async () => {
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BACKEND_URL}/pay_investors/${token}`,
        config
      );
      setIsLoading(false);
      console.log(data);

      if (data.success) setPaySuccess(data);
    } catch (error) {
      setIsLoading(false);
      setError("There was something wrong with the request");
      console.error(error);
    }
  };

  return (
    <>
      <div className="admin-status-container">
        <div className="admin-status">
          {/* {activated === false && (
          <p className="admin-inactivated-status">
            Your account is deactivated,{" "}
            <span className="mobile-hide">Click here to </span>
            <span className="admin-status-leader" onClick={controlModal}>
              Activate now
            </span>
          </p>Z
        )} */}

          {activated === true && (
            <h2 className="admin-owner-status">{title}</h2>
          )}
        </div>
        <div className="button-holder-case mobile-hide">
          <button onClick={handleCloseBids} className="admin-status-bar-btn">
            close all bids
          </button>
          <button className="admin-status-bar-btn" onClick={handlePayInvestors}>
            pay all investors
          </button>
        </div>
      </div>

      <AnimatePresence>
        <ActivationSuccessModal
          modal={!!success}
          onLoad={() => {
            setSuccess(null);
            loadData();
          }}
          title={success?.message}
          desc={`${success?.bidScanned} bids scanned. ${success?.invoicesUpdated} invoices updated. ${success?.nofBidsClosed} bids closed`}
        />
      </AnimatePresence>
      <AnimatePresence>
        <ActivationSuccessModal
          modal={!!paySuccess}
          onLoad={() => {
            setPaySuccess(null);
            loadData();
          }}
          title={paySuccess?.message}
          desc={`${paySuccess?.nofInvestors} investors were successfully paid`}
        />
      </AnimatePresence>
      {/* <AnimatePresence>
        {paySuccess && (
          <AdminSuccessModal
            content={paySuccess}
            onClick={() => {
              setPaySuccess(null);
              loadData();
            }}
          />
        )}
      </AnimatePresence> */}

      {isLoading && <ModalLoader />}

      {error && <Toolkit text={error} onClose={() => setError(null)} />}
    </>
  );
};
